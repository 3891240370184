<template>
  <div class="my">
    <img src="~img/39.png" class="bg-img">
    <div class="user">
      <div>
        <img :src="user.avatar" alt="">
        <p class="name">{{user.name}}</p>
      </div>
      <button @click="sing" v-if="!user.is_sign">签到</button>
    </div>
    <div class="info">
      <div class="left">
        <p class="p1">{{user.credit.count_points}}</p>
        <p class="p2">总积分</p>
      </div>
      <div class="divider"></div>
      <div class="left">
        <p class="p1">{{user.credit.points}}</p>
        <p class="p2">可用积分</p>
      </div>
      <div class="divider"></div>
      <div class="right">
        <p class="p1">{{user.credit.diff_time}}</p>
        <p class="p2">公益时长(h)</p>
      </div>
    </div>
    <ul>
      <li>
        <router-link to="information">
          <div>
            <img src="~img/41.png">
            <p class="p1">个人资料</p>
          </div>
          <van-icon name="arrow" color="#9F9F9F" />
        </router-link>
      </li>
      <li>
        <router-link to="myActivity">
          <div>
            <img src="~img/42.png">
            <p class="p1">我的公益</p>
          </div>
          <van-icon name="arrow" color="#9F9F9F" />
        </router-link>
      </li>
      <li>
        <router-link to="myComment">
          <div>
            <img src="~img/43.png">
            <p class="p1">我的评论</p>
          </div>
          <van-icon name="arrow" color="#9F9F9F" />
        </router-link>
      </li>
      <li>
        <router-link to="integralHistory">
          <div>
            <img src="~img/44.png">
            <p class="p1">积分历史</p>
          </div>
          <van-icon name="arrow" color="#9F9F9F" />
        </router-link>
      </li>
    </ul>
    <button class="outbtn" @click="out">退出登陆</button>
    <tabbar />
  </div>
</template>


<script>
import tabbar from "../../components/Tabbar.vue";
import wxShare from "../../utils/wxShare.js";
export default {
  components: {
    tabbar,
  },
  data() {
    return {
      user: {
        credit: {},
      },
    };
  },
  created() {
    this.getmyInfo();
  },
  mounted() {
    wxShare.commShare("my");
  },
  methods: {
    async getmyInfo() {
      const res = await this.axios.get("user/info");
      console.log(res);
      if (res.code === 200) {
        this.user = res.data;
      }
    },
    async sing() {
      const res = await this.axios.post("user/sign");
      if (res.code === 200) {
        console.log(res);
        this.$toast("签到成功");
        this.getmyInfo();
      } else if (res.code === 400) {
        this.$toast("已签到");
      }
    },
    out() {
      this.$router.push({ path: "/login" });
      localStorage.setItem('user', '')
      localStorage.setItem('token', '')
    }
  },
};
</script>


<style lang="less" scoped>
.my {
  position: relative;
  padding-top: 96px;
  .bg-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .user {
    position: relative;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
    }
    img {
      width: 111px;
      height: 111px;
      border-radius: 10px;
      margin-right: 26px;
    }
    .name {
      font-size: 32px;
      color: #fff;
    }
    button {
      width: 138px;
      height: 62px;
      background: url("../../assets/img/40.png") no-repeat;
      background-size: contain;
      font-size: 32px;
      color: #fff;
    }
  }
  .info {
    width: 92%;
    height: 146px;
    background: #ffffff;
    border-radius: 18px;
    margin: auto;
    position: relative;
    margin-top: 76px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .p1 {
      font-size: 46px;
      color: #484848;
    }
    .p2 {
      font-size: 24px;
      color: #909092;
    }
    .left {
      width: 49.5%;
    }
    .divider {
      width: 2px;
      height: 64px;
      background: #d0d0d0;
    }
    .right {
      width: 49.5%;
    }
  }
  ul {
    width: 92%;
    margin: auto;
    margin-top: 19px;
    border-radius: 18px;
    background: #fff;
    padding: 0 40px;
    li {
      height: 103px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ebebeb;
      a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      div {
        display: flex;
        align-items: center;
        img {
          width: 38px;
          margin-right: 23px;
        }
        .p1 {
          font-size: 28px;
          color: #000;
        }
      }
      .van-icon {
        font-size: 14px;
      }
    }
    li:last-child {
      border: none;
    }
  }
  .outbtn {
    margin: auto;
    display: block;
    width: 90%;
    height: 80px;
    font-size: 24px;
    margin-top: 40px;
    color: #fff;
    background-color: #ee0a24;
    border-radius: 10px;
  }
}
</style>